<script setup>
import {onMounted, ref} from "vue";
import Swal from 'sweetalert2'
import {useAppStore} from "../../store/app";
import {useAuthStore} from "../../store/auth";

const appStore = useAppStore()
const authStore = useAuthStore()
const count = ref(0);
onMounted(() => {
    window.Echo.channel(`orders_created`)
        .listen('OrderCreatedEvent', (e) => {
            if (!authStore.user?.is_admin) {
                return;
            }
            count.value += 1;
            Swal.fire({
                html: getNotification(e.order),
                showConfirmButton: false,
                focusConfirm: false,
                position: 'top-end',
                width: 600,
                padding: '1em',
                background: '#fff',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
            });
        });
});

const getNotification = (order) => {
    let formatter = new Intl.NumberFormat('en-US');
    let amount = formatter.format(order?.items_price);
    let currency = appStore.currencySign;
    let url = `/four/admin/orders/${order?.id}`;

    console.log(order);

    let template = `<div class="tw-rounded-md  tw-p-4">
                      <div class="tw-flex">
                        <div class="tw-flex-shrink-0">
                    <!--      <svg class="tw-h-5 tw-w-5 tw-text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
                    <!--        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />-->
                    <!--      </svg>-->
                        </div>
                        <div class="tw-ml-3">
                          <h3 class="tw-text-sm tw-font-medium tw-text-green-800">Order ${order?.order_id}</h3>
                          <div class="tw-mt-2 tw-text-sm">
                            <p>
                            A new order from <span class="tw-font-bold tw-text-gray-400">${order?.buyer?.name}</span>
                           to <span class="tw-font-bold tw-text-gray-400">${order?.seller?.name}</span>
                           worth <span class="tw-font-semibold tw-text-gray-800">${currency}${amount}</span>
                           has been created.
                           </p>
                    </p>
                          </div>
                          <div class="tw-mt-4">
                            <div class="tw--mx-2 tw--my-1.5 tw-flex">
                              <a id="orderlink" class="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md tw-bg-indigo-600 tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">
                                  <svg class="tw--ml-0.5 tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                  </svg>
                                View Order.
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(template, 'text/html');
    let link = doc.getElementById('orderlink');
    link.href = url;
    return doc.body.innerHTML;
}
</script>

<template>

</template>

<style scoped>

</style>
