<template>
    <div>
        <div
            class="modal fade"
            id="makepayment"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modelTitleId"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Make Payment</h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <makepayment
                                :balance="balance_clear"
                                :currency="currency"
                                :borrowinglimit="borrowinglimit_clear"
                            ></makepayment>
                        </div>
                    </div>
                    <!--                    <div class="modal-footer">-->
                    <!--                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
                    <!--                      <button type="button" class="btn btn-primary">Save</button>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="maketmarketpayment"
            tabindex="-1"
            role="dialog"
            aria-labelledby="marketPaymentId"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Pay Market Fees</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <fees></fees>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-outline-danger"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="topupWallet"
            tabindex="-1"
            role="dialog"
            aria-labelledby="topupWalletId"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="border-bottom: none">
                        <h5
                            class="modal-title"
                            style="color: #4b9d30; font-weight: 600"
                        >
                            Make Deposit
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <!--Label: For you, Attributes:for_you -->
                        <!--            <div class="form-group">-->
                        <!--              <label for="for_you">For you</label>-->
                        <!--              <select type="text" class="form-control" id="for_you" v-model="for_you"-->
                        <!--                      disabled-->
                        <!--                      aria-describedby="for_you-help"-->
                        <!--                      :class="[errors.for_you ? 'is-invalid': '',!errors.for_you && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
                        <!--              >-->
                        <!--                <option value="for_you">Yes</option>-->
                        <!--                <option value="for_you">No</option>-->
                        <!--              </select>-->
                        <!--              <div class="invalid-feedback" v-if="errors.for_you">-->
                        <!--                {{ errors.for_you.toString() }}-->
                        <!--              </div>-->
                        <!--            </div>-->
                        <!--Label: Amount to Top Up, Attributes:amount -->
                        <div
                            style="
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                margin-bottom: 1rem;
                            "
                            v-if="locale === 'ng'"
                        >
                            <div
                                style="
                                    border-radius: 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    padding: 0.56rem 1.25rem;
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    cursor: pointer;
                                "
                                :style="
                                    this.topShow
                                        ? 'background: #4B9D30'
                                        : 'background: #fff'
                                "
                                @click="this.toggleTopShow"
                            >
                                <div>
                                    <p
                                        style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 700;
                                            margin: 0;
                                            padding: 0;
                                        "
                                        :style="
                                            this.topShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                    >
                                        Deposit with Account Number
                                    </p>

                                    <p
                                        style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                        :style="
                                            this.topShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                    >
                                        Deposit Directly to your Account Number
                                    </p>
                                </div>
                                <svg
                                    width="8"
                                    height="5"
                                    viewBox="0 0 8 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.77356 0.209396L3.99593 2.98702L1.2183 0.209396C0.939109 -0.0697987 0.488102 -0.0697987 0.208908 0.209396C-0.0702869 0.488591 -0.0702869 0.939597 0.208908 1.21879L3.49481 4.5047C3.77401 4.78389 4.22502 4.78389 4.50421 4.5047L7.79012 1.21879C8.06931 0.939597 8.06931 0.488591 7.79012 0.209396C7.51092 -0.0626398 7.05276 -0.0697987 6.77356 0.209396Z"
                                        :fill="
                                            this.topShow ? 'white' : '#4B9D30'
                                        "
                                    />
                                </svg>
                            </div>
                            <div
                                style="
                                    border-radius: 0 0 0.25rem 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    border-top: none;
                                    padding: 1rem;
                                    width: 90%;
                                    margin: 0 auto;
                                    justify-content: space-between;
                                    align-items: center;
                                    background: #fff;
                                    font-size: 0.75rem;
                                "
                                :style="
                                    this.topShow
                                        ? 'display: flex'
                                        : 'display: none'
                                "
                            >
                                <div>
                                    <p
                                        style="
                                            font-weight: 700;
                                            margin: 0;
                                            padding: 0;
                                            color: #4b9d30;
                                        "
                                    >
                                        Make deposit directly to your account
                                    </p>
                                    <p
                                        style="
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                    >
                                        <span style="font-weight: 600"
                                        >Bank:</span
                                        >
                                        {{ this.userBankName }}
                                    </p>

                                    <p
                                        style="
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                    >
                                        <span style="font-weight: 600"
                                        >Account Name:</span
                                        >
                                        {{ this.userAccountName }}
                                    </p>

                                    <p
                                        style="
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                    >
                                        <span style="font-weight: 600"
                                        >Account Number:
                                        </span>
                                        {{ this.userAccountNumber }}
                                    </p>
                                </div>
                                <svg
                                    width="8"
                                    height="5"
                                    viewBox="0 0 8 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.77356 0.209396L3.99593 2.98702L1.2183 0.209396C0.939109 -0.0697987 0.488102 -0.0697987 0.208908 0.209396C-0.0702869 0.488591 -0.0702869 0.939597 0.208908 1.21879L3.49481 4.5047C3.77401 4.78389 4.22502 4.78389 4.50421 4.5047L7.79012 1.21879C8.06931 0.939597 8.06931 0.488591 7.79012 0.209396C7.51092 -0.0626398 7.05276 -0.0697987 6.77356 0.209396Z"
                                        :fill="
                                            this.topShow ? 'white' : '#4B9D30'
                                        "
                                    />
                                </svg>
                            </div>
                        </div>
                        <template v-if="locale === 'ke'">
                            <div
                                style="
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                margin-bottom: 1rem;
                            "
                            >
                                <div
                                    style="
                                    border-radius: 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    padding: 0.56rem 1.25rem;
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    cursor: pointer;
                                "
                                    :style="
                                    this.bottomShow
                                        ? 'background: #4B9D30'
                                        : 'background: #fff'
                                "
                                    @click="this.toggleBottomShow"
                                >
                                    <div>
                                        <p
                                            style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 700;
                                            margin: 0;
                                            padding: 0;
                                        "
                                            :style="
                                            this.bottomShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                        >
                                            Top up with MPESA
                                        </p>

                                        <p
                                            style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                            :style="
                                            this.bottomShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                        >
                                            Top up with M-PESA
                                        </p>
                                    </div>
                                    <svg
                                        width="8"
                                        height="5"
                                        viewBox="0 0 8 5"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.77356 0.209396L3.99593 2.98702L1.2183 0.209396C0.939109 -0.0697987 0.488102 -0.0697987 0.208908 0.209396C-0.0702869 0.488591 -0.0702869 0.939597 0.208908 1.21879L3.49481 4.5047C3.77401 4.78389 4.22502 4.78389 4.50421 4.5047L7.79012 1.21879C8.06931 0.939597 8.06931 0.488591 7.79012 0.209396C7.51092 -0.0626398 7.05276 -0.0697987 6.77356 0.209396Z"
                                            :fill="
                                            this.bottomShow
                                                ? 'white'
                                                : '#4B9D30'
                                        "
                                        />
                                    </svg>
                                </div>
                                <div
                                    style="
                                    border-radius: 0 0 0.25rem 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    border-top: none;
                                    padding: 1rem;
                                    width: 90%;
                                    margin: 0 auto;
                                    justify-content: space-between;
                                    align-items: center;
                                    background: #fff;
                                    font-size: 0.75rem;
                                    flex-direction: column;
                                "
                                    :style="
                                    this.bottomShow
                                        ? 'display: flex'
                                        : 'display: none'
                                "
                                >
                                    <div class="row tw-w-full">
                                        <div class="tw-mt-2 tw-w-full">
                                            <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3 tw-mb-2">
                                                <span class="tw-text-gray-600 tw-font-medium">Bank:</span>
                                                <span class="tw-text-gray-800 tw-font-semibold">M-PESA Pay Bill</span>
                                            </div>
                                            <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3 tw-mb-2">
                                                <span class="tw-text-gray-600 tw-font-medium">Paybill:</span>
                                                <span class="tw-text-gray-800 tw-font-semibold">4109245</span>
                                            </div>
                                            <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3 tw-mb-2">
                                                <span class="tw-text-gray-600 tw-font-medium">Account Number:</span>
                                                <span class="tw-text-gray-800 tw-font-semibold">{{user?.phone}}</span>
                                            </div>


                                        </div>
                                    </div>

                                    <div
                                        class="modal-footer"
                                        style="border-top: none"
                                    >
                                        <template v-if="locale === 'ke'">

                                        </template>
                                        <template v-else>
                                            <paystack
                                                buttonClass="btn btn-success"
                                                style="padding: 0.5rem 3.5rem"
                                                buttonText="Deposit"
                                                :publicKey="publicKey"
                                                :email="email ? email : 'finance@coamana.com'"
                                                :currency="'NGN'"
                                                :amount="gatewayfees * 100"
                                                :reference="reference"
                                                :onSuccess="onSuccessfulPayment"
                                                :onCancel="onCancelledPayment"
                                            >
                                            </paystack>
                                        </template>

                                        <!--                    <flutterwave-pay-button-->
                                        <!--                        class="btn btn-primary"-->
                                        <!--                        v-bind="paymentData"-->
                                        <!--                        :callback="makePaymentCallback"-->
                                        <!--                        :onclose="closedPaymentModal"-->
                                        <!--                    > Top Up Wallet-->
                                        <!--                    </flutterwave-pay-button>-->
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                style="
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                margin-bottom: 1rem;
                            "
                            >
                                <div
                                    style="
                                    border-radius: 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    padding: 0.56rem 1.25rem;
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    cursor: pointer;
                                "
                                    :style="
                                    this.bottomShow
                                        ? 'background: #4B9D30'
                                        : 'background: #fff'
                                "
                                    @click="this.toggleBottomShow"
                                >
                                    <div>
                                        <p
                                            style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 700;
                                            margin: 0;
                                            padding: 0;
                                        "
                                            :style="
                                            this.bottomShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                        >
                                            Deposit with online (card/USSD)
                                        </p>

                                        <p
                                            style="
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 400;
                                            margin: 0;
                                            padding: 0;
                                        "
                                            :style="
                                            this.bottomShow
                                                ? 'color: #fff'
                                                : 'color: #4B9D30'
                                        "
                                        >
                                            Make an online payment
                                        </p>
                                    </div>
                                    <svg
                                        width="8"
                                        height="5"
                                        viewBox="0 0 8 5"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.77356 0.209396L3.99593 2.98702L1.2183 0.209396C0.939109 -0.0697987 0.488102 -0.0697987 0.208908 0.209396C-0.0702869 0.488591 -0.0702869 0.939597 0.208908 1.21879L3.49481 4.5047C3.77401 4.78389 4.22502 4.78389 4.50421 4.5047L7.79012 1.21879C8.06931 0.939597 8.06931 0.488591 7.79012 0.209396C7.51092 -0.0626398 7.05276 -0.0697987 6.77356 0.209396Z"
                                            :fill="
                                            this.bottomShow
                                                ? 'white'
                                                : '#4B9D30'
                                        "
                                        />
                                    </svg>
                                </div>
                                <div
                                    style="
                                    border-radius: 0 0 0.25rem 0.25rem;
                                    border: 0.4px solid #4b9d30;
                                    border-top: none;
                                    padding: 1rem;
                                    width: 90%;
                                    margin: 0 auto;
                                    justify-content: space-between;
                                    align-items: center;
                                    background: #fff;
                                    font-size: 0.75rem;
                                    flex-direction: column;
                                "
                                    :style="
                                    this.bottomShow
                                        ? 'display: flex'
                                        : 'display: none'
                                "
                                >
                                    <div class="w-100 form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="amount"
                                            v-model.number="amount"
                                            aria-describedby="amount-help"
                                            :class="[
                                            errors.amount ? 'is-invalid' : '',
                                            !errors.amount &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        />
                                        <label for="amount">Amount to Top Up</label>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.amount"
                                        >
                                            {{ errors.amount.toString() }}
                                        </div>
                                    </div>

                                    <div class="w-100 form-floating mt-3">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="paystackcharges"
                                            id="paystackcharges"
                                            aria-describedby="gatewayHelpId"
                                            disabled
                                            :value="
                                            paystackfees
                                                ? Number(
                                                      paystackfees
                                                  )?.toLocaleString()
                                                : ''
                                        "
                                        />
                                        <label for="paystackcharges"
                                        >Payment portal charges
                                        </label>

                                        <!-- <small -->
                                        <!--     id="gatewayHelpId" -->
                                        <!--     class="form-text text-muted" -->
                                        <!--     >The third-party payment service -->
                                        <!--     provider imposes a fee of {{ currency -->
                                        <!--     }}{{ paystackfees }} for utilizing their -->
                                        <!--     technology to top up your wallet. -->
                                        <!-- </small> -->
                                    </div>

                                    <div class="w-100 form-floating mt-3">
                                        <!-- class="form-control fw-bold active-faded-background" -->
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="gatewayfees"
                                            id="gatewayfees"
                                            aria-describedby="gatewayHelpId"
                                            disabled
                                            :value="
                                            gatewayfees
                                                ? Number(
                                                      gatewayfees
                                                  )?.toLocaleString()
                                                : ''
                                        "
                                            placeholder="gatewayfees"
                                        />
                                        <label for="gatewayfees"
                                        >Total to pay
                                        </label>
                                        <!--              <small id="gatewayHelpId" class="form-text text-muted">Fees charged by our payment provider.</small>-->
                                    </div>
                                    <div
                                        class="modal-footer"
                                        style="border-top: none"
                                    >
                                        <paystack
                                            buttonClass="btn btn-success"
                                            style="padding: 0.5rem 3.5rem"
                                            buttonText="Deposit"
                                            :publicKey="publicKey"
                                            :email="email ? email : 'finance@coamana.com'"
                                            :currency="'NGN'"
                                            :amount="gatewayfees * 100"
                                            :reference="reference"
                                            :onSuccess="onSuccessfulPayment"
                                            :onCancel="onCancelledPayment"
                                        >
                                        </paystack>
                                        <!--                    <flutterwave-pay-button-->
                                        <!--                        class="btn btn-primary"-->
                                        <!--                        v-bind="paymentData"-->
                                        <!--                        :callback="makePaymentCallback"-->
                                        <!--                        :onclose="closedPaymentModal"-->
                                        <!--                    > Top Up Wallet-->
                                        <!--                    </flutterwave-pay-button>-->
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="makeWithdraw"
            tabindex="-1"
            role="dialog"
            aria-labelledby="makeWithdrawId"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Make Withdrawal</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row g-3">
                            <div class="col-12">
                                <div style="padding-right: 30px;padding-left: 30px">
                                    <button
                                        type="button"
                                        style="height: 80px"
                                        class="col-12 btn btn-outline-success btn-lg"
                                        @click="onClickWithdrawBank"
                                    >
                                        <span style="float: left">Withdraw to Bank</span>

                                        <span class="" style="float: right">
                                            <i class="fa fa-solid fa-bank" style="font-size: 40px;"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 g-3" v-if="locale === 'ng'">
                                <div style="padding-right: 30px;padding-left: 30px">
                                    <button
                                        type="button"
                                        style="height: 80px;"
                                        class="col-12 btn-momo btn btn-outline-info btn-lg"
                                        @click="onClickWithdrawMomo"
                                    >
                                        <span style="float: left">Withdraw using MoMo</span>

                                        <span class="ms-auto" style="float: right">
                                            <img height="40" src="/images/momo_logo.webp"/>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="withdraw"
            tabindex="-1"
            role="dialog"
            aria-labelledby="withdrawId"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Make Withdrawal</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="transactionInitiated">
                            <div class="alert alert-success" role="alert">
                                <p>
                                    Your withdrawal request has been initiated.
                                    You will receive a text message once money
                                    has been transferred to your account.
                                </p>
                                <p>
                                    <strong> {{ message }}</strong>
                                </p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row g-3">
                                <!--<div class="form-group">
                                    <label for="for_you">For you</label>
                                    <select
                                        type="text"
                                        class="form-control"
                                        id="for_you"
                                        v-model="for_you"
                                        disabled
                                        aria-describedby="for_you-help"
                                        :class="[
                                            errors.for_you ? 'is-invalid' : '',
                                            !errors.for_you &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option value="for_you">Yes</option>
                                        <option value="for_you">No</option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.for_you"
                                    >
                                        {{ errors.for_you.toString() }}
                                    </div>
                                </div>-->
                                <!--Label: Bank, Attributes:bank -->
                                <div class="form-floating">
                                    <select
                                        class="form-select"
                                        id="bank"
                                        v-model="bank"
                                        aria-describedby="bank-help"
                                        :class="[
                                            errors.bank ? 'is-invalid' : '',
                                            !errors.bank &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                        <option
                                            :value="bank.code"
                                            :key="bank.code"
                                            v-for="bank in banks"
                                        >
                                            {{ bank.name }}
                                        </option>
                                    </select>
                                    <label for="bank">Bank</label>
                                    <div class="invalid-feedback" v-if="errors.bank">
                                        {{ errors.bank.toString() }}
                                    </div>
                                </div>

                                <!--Label: Account Number, Attributes:account_number -->
                                <div class="form-floating">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="account_number"
                                        v-model="account_number"
                                        aria-describedby="account_number-help"
                                        :class="[
                                            errors.account_number
                                                ? 'is-invalid'
                                                : '',
                                            !errors.account_number &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        required
                                    />
                                    <label for="account_number">Account Number</label>

                                    <div class="invalid-feedback" v-if="errors.account_number">
                                        {{ errors.account_number.toString() }}
                                    </div>
                                </div>

                                <!--Label: Amount to Top Up, Attributes:amount -->
                                <div class="form-floating">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="amount_to_withdraw"
                                        v-model.number="amount_to_withdraw"
                                        aria-describedby="amount_to_withdraw-help"
                                        :class="[
                                            errors.amount_to_withdraw
                                                ? 'is-invalid'
                                                : '',
                                            !errors.amount_to_withdraw &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                        required
                                    />
                                    <label for="amount">Amount to WithDraw</label>
                                    <div class="invalid-feedback" v-if="errors.amount_to_withdraw">
                                        {{ errors.amount_to_withdraw.toString() }}
                                    </div>
                                </div>

                                <!--Label: Amount to Top Up, Attributes:amount -->
                                <!--                <div class="form-floating" v-if="amount_to_withdraw > 0">-->
                                <!--                  <input type="number" class="form-control" id="amount_to_withdraw"-->
                                <!--                         disabled-->
                                <!--                         v-model.number="totalamount"-->
                                <!--                         aria-describedby="amount_to_withdraw-help"-->
                                <!--                         :class="[errors.amount_to_withdraw ? 'is-invalid': '',!errors.amount_to_withdraw && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
                                <!--                         required>-->
                                <!--                  <label for="amount">Total Withdrawal with Gateway Charges</label>-->
                                <!--                  <div class="invalid-feedback" v-if="errors.amount_to_withdraw">-->
                                <!--                    {{ errors.amount_to_withdraw.toString() }}-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <template v-if="totalamount > parseInt(balance)">
                                    <div class="mt-2">
                                        <div class="alert alert-danger" role="alert">
                                            <strong>
                                                The amount you are trying to withdraw exceeds your wallet balance.
                                            </strong>
                                        </div>
                                    </div>
                                </template>

                                <ul class="list-group" v-if="showOtp">
                                    <li class="list-group-item">
                                        <dl>
                                            <dt>Account Name</dt>
                                            <dd>
                                                <template v-if="searchingDetails">
                                                    <i class="fa fa-spin fa-spinner"></i>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        accountDetails?.accountDetails?.account_name ?? "No Account was found"
                                                    }}
                                                </template>
                                            </dd>
                                            <dt>Account Number</dt>

                                            <dd>
                                                <template v-if="searchingDetails">
                                                    <i class="fa fa-spin fa-spinner"></i>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        accountDetails?.accountDetails?.account_number ?? "No Account was found"
                                                    }}
                                                </template>
                                            </dd>
                                        </dl>
                                    </li>
                                </ul>
                                <!--Label: OTP, Attributes:otp -->
                                <div class="form-floating" v-if="complete">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="otp"
                                        v-model="otp"
                                        aria-describedby="otp-help"
                                        :class="[
                                            errors.otp ? 'is-invalid' : '',
                                            !errors.otp &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    />
                                    <label for="otp">OTP</label>
                                    <div class="invalid-feedback" v-if="errors.otp">
                                        {{ errors.otp.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <ResendOTP v-if="complete" :send-otp="sendOtp" ref="childRef"/>
                        <button
                            type="button"
                            class="btn warn-button-border"
                            @click.prevent="onClickClose"
                        >
                            Close
                        </button>
                        <div v-if="transactionInitiated"></div>
                        <div v-else>
                            <button
                                type="button"
                                class="btn text-white add-button-coamana"
                                @click.prevent="confirmBankingDetails"
                                :disabled="totalamount > parseInt(balance)"
                                v-if="!complete"
                            >
                                WithDraw
                                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click.prevent="withdraw"
                                :disabled="saving"
                                v-else
                            >
                                Complete
                                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {FlutterwavePayButton} from "flutterwave-vue-v3"
import paystack from "vue3-paystack";
import Makepayment from "./makepayment.vue";
import Fees from "../wallet/feesandrent/Index.vue";
import ResendOTP from "../ResendOTP.vue";
import Swal from "sweetalert2";
import {useAppStore} from "../../store/app";


export default {
    name: "index",
    props: [
        "balance",
        "total_topped",
        "total_sent",
        "transactions",
        "banks",
        "currency",
        "amountborrowed",
        "borrowinglimit",
        "creditscore",
        "balance_clear",
        "borrowinglimit_clear",
        "amounttowithdraw",
    ],
    mounted() {
        this.user = JSON.parse(
            document.querySelector('meta[name="user"]').content
        );

        if (
            this.amounttowithdraw != undefined ||
            this.amounttowithdraw != null
        ) {
            this.amount_to_withdraw = this.amounttowithdraw;
        }
        this.paymentData.currency = this.currency;
        this.paymentData.meta.consumer_id = this.user.id;
        this.paymentData.meta.consumer_phone = this.user.phone;
        this.paymentData.customer.name = this.user.name;
        this.paymentData.customer.email = this.user.email ?? "info@coamana.com";
        this.email = this.user.email ?? "info@coamana.com";
        this.paymentData.customer.phone_number = this.user.phone;
        this.getBankDetails();
    },
    data() {
        const app = useAppStore();
        // console.log(import.meta.env.VITE_PAYSTACK_PUBLIC_KEY)
        return {
            accountDetails: [],
            errors: "",
            // gatewayfees: "",
            locale: app.country,
            withdrawalId: "",
            showOtp: false,
            searchingDetails: false,
            transactionInitiated: false,
            saving: false,
            for_you: "for_you",
            user: "",
            amount: 0,
            publicKey: import.meta.env.VITE_PAYSTACK_PUBLIC_KEY,
            email: "info@coamana.com",
            reference: this.generateReference(),
            paymentData: {
                tx_ref: this.generateReference(),
                amount: this.amount,
                currency: import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY,
                payment_options: "card,ussd",
                redirect_url: ``,
                meta: {
                    consumer_id: "",
                    consumer_phone: "",
                    consumer_mac: "kjs9s8ss7dd",
                },
                customer: {
                    name: "",
                    email: "",
                    phone_number: "",
                },
                customizations: {
                    title: "Amana Market",
                    description: "Amana Market",
                    logo: "https://flutterwave.com/images/logo-colored.svg",
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal,
            },

            bank: "",
            account_number: "",
            totalamount: 0,
            amount_to_withdraw: "",
            otp: "",
            complete: false,
            message: "",
            paystackfees: 0,
            topShow: false,
            bottomShow: false,
            loadingUserBankDetails: true,
            userAccountName: "",
            userBankName: "VfB Microfinance Bank",
            userAccountNumber: "",
            phone_number: '',
            depositing: false,
        };
    },
    components: {
        ResendOTP,
        Fees,
        Makepayment,
        paystack,
    },
    methods: {
        depositViaMPESA() {
            this.depositing = true;
            axios.post('/apis/v4/topup/wallet/mpesa', {
                'phone': this.phone_number,
                'amount': this.amount,
                'reason': 'topup'
            }).then(() => {
                this.depositing = false;
                location.reload();
            }).finally(() => {
                location.reload();
            });
        },
        totalAmount() {
            return 500;
        },
        toggleTopShow() {
            this.topShow = !this.topShow;
        },
        toggleBottomShow() {
            this.bottomShow = !this.bottomShow;
        },
        getBankDetails() {
            this.loadingUserBankDetails = true;
            axios
                .post("/apis/v4/me")
                .then((resp) => {
                    this.userAccountName =
                        resp?.data?.data?.pool_bank_account?.account_name ?? "Account not yet created";
                    this.userAccountNumber =
                        resp?.data?.data?.pool_bank_account?.account_number ?? "Account not yet created";
                })
                .catch(() => {
                    this.loadingUserBankDetails = false;
                });
        },
        confirmBankingDetails() {
            this.showOtp = true;
            this.searchingDetails = true;
            this.saving = true;
            axios
                .post("/apis/v4/bank-account-info", {
                    bank: this.bank,
                    account_number: this.account_number,
                    user_id: this.user.id,
                    amount_to_withdraw: this.amount_to_withdraw,
                })
                .then((resp) => {
                    this.saving = false;
                    this.searchingDetails = false;
                    this.accountDetails = resp.data;
                    if (this.accountDetails?.accountDetails?.account_name ?? null) {
                        this.sendOtp()
                    }
                })
                .catch((err) => {
                    this.errors = err.response.data.errors;
                    this.showOtp = false;
                })
                .finally(() => {
                    this.saving = false;
                    this.searchingDetails = false;
                });
        },
        sendOtp() {
            this.complete = true;
            this.saving = true;
            axios
                .post("/apis/v4/withdraw/otp", {
                    bank_id: this.bank,
                    account_number: this.account_number,
                    user_id: this.user.id,
                    amount: this.amount_to_withdraw,
                })
                .then((resp) => {
                    this.saving = false;
                    this.withdrawalId = resp.data.id;
                    this.$refs.childRef.startTimer();
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        withdraw() {
            this.saving = true;
            // previously /api/withdraw and was change to current (to create requests for approval when user withdraws)
            axios
                .post("/apis/v4/saas/withdrawal-requests", {
                    withdrawal_id: this.withdrawalId,
                    otp: this.otp,
                    type: 'user',
                    wallet_type: 'user',
                })
                .then((resp) => {
                    this.saving = false;
                    this.transactionInitiated = true;
                    this.errors = {};
                    this.message = resp.data.message;
                })
                .catch((err) => {
                    this.saving = false;
                    let valError = err.response.data.errors;
                    this.errors = valError ? valError : "";

                    if (!valError) {
                        Swal.fire({
                            position: 'top',
                            icon: 'error',
                            title: 'Error withdrawing. Please try again and contact our support team if problem persists.',
                        });
                    }
                });
        },
        onSuccessfulPayment(response) {
            axios
                .post("/api/wallet/topup", {
                    success: response,
                    payload: this.paymentData,
                    intended_top_up: this.amount,
                })
                .then((resp) => {
                    window.location.reload();
                })
                .catch((err) => {
                    window.location.reload();
                });
        },
        onCancelledPayment(response) {
            console.log('Payment cancelled');
        },
        makePaymentCallback(response) {
            axios
                .post("/api/wallet/topup", {
                    success: response,
                    payload: this.paymentData,
                })
                .then((resp) => {
                    window.location.reload();
                })
                .catch((err) => {
                    window.location.reload();
                });
        },
        closedPaymentModal() {
            console.log("payment modal is closed");
        },
        generateReference() {
            let date = new Date();
            return date.getTime().toString();
        },
        onClickWithdrawBank() {
            this.bank = '';

            let myModalEl = document.querySelector("#withdraw");
            let modal = myModalEl ? bootstrap.Modal.getOrCreateInstance(myModalEl) : null;
            modal?.show();
        },
        onClickWithdrawMomo() {
            this.banks.forEach((bank) => {
                if (bank.name === "MTN Momo PSB") {
                    this.bank = bank.code;

                    return;
                }
            });

            let myModalEl = document.querySelector("#withdraw");
            let modal = myModalEl ? bootstrap.Modal.getOrCreateInstance(myModalEl) : null;
            modal?.show();
        },
        onClickClose() {
            location.reload();
        },
    },
    computed: {
        totalamount: function () {
            let amount = 0;

            if (this.amount_to_withdraw < 5000) {
                amount = this.amount_to_withdraw;
            }

            if (
                this.amount_to_withdraw > 5000 &&
                this.amount_to_withdraw <= 49999
            ) {
                amount = this.amount_to_withdraw;
            }

            if (this.amount_to_withdraw >= 50000) {
                amount = this.amount_to_withdraw;
            }
            this.totalamount = amount;
            return amount;
        },
        chargedAmount: function () {
            // `this` points to the vm instance
            return this.amount;
        },
        gatewayfees: function () {
            // `this` points to the vm instance
            let paystackFees = 0;

            if (parseInt(this.amount) <= 2000) {
                paystackFees = this.amount * 0.015;
            } else {
                paystackFees = this.amount * 0.015 + 100;
            }

            let total = paystackFees + 160;

            if (this.amounttowithdraw < 1) {
                this.paystackfees = 0;
            } else {
                this.paystackfees = paystackFees + 160;
            }
            if (total >= 2500) {
                total = 2500;
            }
            return this.amount + total;
        },
    },
    watch: {
        amount(newValue, oldValue) {
            this.paymentData.amount = newValue;
        },
    },
};
</script>

<style scoped>
.btn-momo {
    color: darkblue;
    border-color: darkblue;
}

.btn-momo:hover {
    color: #fff;
    background-color: darkblue;
}
</style>
