import './bootstrap'

// import Vue from 'vue'
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import Flutterwave from  'flutterwave-vue-v3'
import {createApp} from 'vue';
import BulkSMS from './components/BulkSms/index.vue';
import MpesaAccountIndex from './components/BulkSms/index.vue';
import ReferralCode from './components/v4/market/compliance/MemberCompliance/ReferralCodeCompliance.vue';
import AssignOrder from './components/Orders/SupplyAgent/AssignOrder.vue';
import PriceIndex from './components/PriceUpdates/Index.vue';
import WalletIndex from './components/wallet/index.vue';
import WalletTransfer from './components/wallet/transfer/Index.vue';
import ProductAddition from './components/v4/products/create.vue';
import PaymentIndex from './components/wallet/payments/Index.vue';
import RequestPayment from './components/wallet/requestpayment/Index.vue';
import CreateFees from "./components/rent_and_fees/Create.vue";
import CreateCharge from "./components/rent_and_fees/CreateCharge.vue";
import DeleteChargeFee from "./components/rent_and_fees/DeleteChargeFee.vue";
import MarketsIndex from "./components/User/Markets/MarketsIndex.vue";
import ReceivePayment from "./components/Payments/ReceivePayment.vue";
import PayFeesIndex from "./components/v4/market/fee/PayFees/Index.vue";
import PayFeesShow from "./components/v4/market/fee/PayFees/Show.vue";
import VerificationIcons from "./components/v4/Verification/UserIcons.vue";
import MarketMembers from "./components/v4/market/members/Index.vue";
import RBACIndex from "./components/v4/admin/rbac/RBACIndex.vue";
import AddUser from "./components/v4/admin/rbac/AddUser.vue";
import ComplianceIndex from "./components/v4/compliance/ComplianceIndex.vue";
import MarketCompliance from "./components/v4/market/compliance/MarketCompliance.vue";
import MarketComplianceFinance from "./components/v4/finance/compliance/MarketComplianceFinance.vue";
import FinanceUserCompliance from "./components/v4/compliance/FinanceUserCompliance.vue";
import CameraCapture from "./components/userpicture/CameraCapture.vue";
import PayForOrder from "./components/wallet/orders/PayForOrder.vue";
import UpcomingPayments from "./components/Landing/UpcomingPayments.vue";
import AcceptOrder from "./components/AcceptOrder.vue";
import MarketUpcomingPayments from "./components/v4/market/MarketUpcomingPayments.vue";
import MemberVerification from "./components/v4/market/compliance/MemberCompliance/Index.vue";
import MarketVerification from "./components/v4/market/compliance/MarketCompliance/Index.vue";
import {createPinia} from 'pinia'
import PersonalInformationEditing from "./components/v4/profile/PersonalInformationEditing.vue";
import UpdatePassword from "./components/v4/profile/UpdatePassword.vue";
import UpdateAccountInformation from "./components/v4/profile/UpdateAccountInformation.vue";
import MarketProfile from "./components/v4/profile/MarketProfile/Index.vue";
import LoginPage from "./components/v4/signin/LoginPage.vue";
import MarketChanger from "./components/MarketChanger.vue";
import MarketCreation from "./components/Admin/Markets/MarketCreation.vue";
import FinanceSettings from "./components/v4/admin/finance/settings/Index.vue";
import MemberManagement from "./components/v4/market/members/MemberManagement.vue";
import ProfilePage from "./components/User/Profile/ProfilePage.vue";
import UserAvatar from "./components/v4/profile/UserAvatar.vue";
import UserNotifications from "./components/v4/profile/UserNotifications.vue";
import SummaryCards from "./components/v4/admin/finance/loan_book/SummaryCards.vue";
import {useAuthStore} from "./store/auth";
import CreditQueue from "./components/v4/admin/finance/creditqueue/CreditQueue.vue";
import MemberCreditQueue from "./components/v4/market/creditqueue/MemberCreditQueue.vue";
import Onboarding from './components/Admin/Markets/OnboardedMarkets/Index.vue';
import BankAccount from "./components/v4/admin/bank_account/BankAccount.vue";
import AddBankAccount from "./components/v4/admin/bank_account/AddBankAccount.vue";
import UpdateBankAccountInfo from "./components/User/Profile/UpdateBankAccountInfo.vue";
import AddUserBankAccount from "./components/v4/user/AddBankAccount.vue";
import BankTransaction from "./components/v4/admin/bank_account/BankTransaction.vue";
import CreateMarketMember from "./components/Admin/Markets/CreateMarketMember.vue";
import WithdrawalRequest from "./components/v4/admin/finance/withdrawal_request/WithdrawalRequest.vue";
import FeeAndCharge from "./components/v4/market/fee/FeeAndCharge.vue";
import FeeDetail from "./components/rent_and_fees/FeeDetail.vue";
import ChargeDetail from "./components/rent_and_fees/ChargeDetail.vue";
import FeeChargeCashout from "./components/rent_and_fees/FeeChargeCashout.vue";
import NewOrder from "./components/v4/admin/finance/orders/NewOrder.vue";
import UserAdministration from "./components/v4/user/UserAdministration.vue";
import OrderPayouts from "./components/v4/admin/finance/payouts/Index.vue";
import Transaction from "./components/v4/market/members/Transaction.vue";
import Subscription from "./components/v4/admin/finance/subscriptions/Subscription.vue";
import StaffAccess from "./components/v4/admin/staff/marketaccess/index.vue";
import Insurance from './components/Admin/Finance/insurance/index.vue';

const pinia = createPinia()
const app = createApp({});

// app.use(Flutterwave, { publicKey: import.meta.env.VITE_FLUTTERWAVE_PUBLIC_KEY } )

window.axios = axios;
app.component('insurance', Insurance);
app.component('LinkMpesaOrders', LinkMpesaPayments);
app.component('reportincidence', ReportIncidence);
app.component('bulksms-index', BulkSMS);
app.component('mpesa-accountsetup', MpesaAccountIndex);
app.component('supply-agent-assign-order', AssignOrder);
app.component('VueDatePicker', VueDatePicker);


// Price Updates
app.component('priceindex', PriceIndex);
app.component('updatebankaccount', AddUserBankAccount);

// Wallet
app.component('top-up-wallet', WalletIndex);
app.component('wallet-transfer', WalletTransfer);
app.component('make-wallet-payment', PaymentIndex);
app.component('request-payment', RequestPayment);

// fees_rent_and_taxes
app.component('create-fees', CreateFees);
app.component('create-charge', CreateCharge);
app.component('delete-charge-fee', DeleteChargeFee);
app.component('fee-detail', FeeDetail);
app.component('charge-detail', ChargeDetail);
app.component('fee-charge', FeeAndCharge);
app.component('fee-charge-cashout', FeeChargeCashout);

app.component('markets-index', MarketsIndex);
app.component('markets-members', MarketMembers);

app.component('payments-index', ReceivePayment);
app.component('pay-fees-index', PayFeesIndex);
app.component('pay-fees-show', PayFeesShow);


app.component('loan-book-summary-cards', SummaryCards);

app.component('rbac-add-admin', AddUser);

app.component('compliance-index', ComplianceIndex);
app.component('market-compliance-index', MarketCompliance);
app.component('market-compliance-finance-index', MarketComplianceFinance);
app.component('finance-user-compliance', FinanceUserCompliance);

app.component('camera-capture', CameraCapture);
app.component('payfororder', PayForOrder);
app.component('upcomingpayments', UpcomingPayments);
app.component('marketupcomingpayments', MarketUpcomingPayments);
app.component('acceptorder', AcceptOrder);

app.component('memberverification', MemberVerification);
app.component('marketverification', MarketVerification);
app.component('personalinfoedit', PersonalInformationEditing);
app.component('updatepassword', UpdatePassword);
app.component('accountinformation', UpdateAccountInformation);
app.component('MarketProfile', MarketProfile);
app.component('LoginPage', LoginPage);
app.component('market-changer', MarketChanger);
app.component('upcoming-payments-page', UpcomingPayments);
app.component('create-market', MarketCreation);
app.component('create-market-member', CreateMarketMember);
app.component('finance-settings', FinanceSettings);

app.component('rbac-index', RBACIndex);
app.component('member-management', MemberManagement);

/*Profile page*/
app.component('my-profile-management', ProfilePage);
app.component('user-avatar', UserAvatar);
app.component('notifications', UserNotifications);


app.component('productsversiontwo', ProductAddition);
app.component('verificationicon', VerificationIcons);
app.component('referralcode', ReferralCode);
app.component('credit-queue', CreditQueue);
app.component('member-credit-queue', MemberCreditQueue);
app.component('onboarded-markets', Onboarding);
app.component('bank-account', BankAccount);
app.component('add-bank-account', AddBankAccount);
app.component('bank-transaction', BankTransaction);

app.component('update-bank-account-info', UpdateBankAccountInfo);

app.component('withdrawal-request', WithdrawalRequest);
app.component('create-order-by-staff', NewOrder);
app.component('user-administration', UserAdministration);
app.component('order-payouts', OrderPayouts);
app.component('member-transactions', Transaction);
app.component('subscription', Subscription);
app.component('staffmarketaccess', StaffAccess);
app.component('pos-application', PosApplication);
app.component('finance-order', FinanceOrder);

app.component('product', Product);
app.component('transactionstatuske', transactionstatus);

app.component('member-earning', Earning);
app.component('admin-notifications', AdminNotifications);


// app.component('pending-payments-component', require('./components/BulkPayments/PendingPaymentIndex.vue').default);

app.use(pinia)
import { createI18n } from 'vue-i18n'
import Product from "./components/v4/products/Product.vue";
import ReportIncidence from "./components/Payments/ReportIncidence.vue";
import transactionstatus from "./components/Admin/Finance/mpesa/payouts/transactionstatus.vue";
import Earning from "./components/v4/market/members/Earning.vue";
import PosApplication from "./components/v4/admin/finance/pos_application/PosApplication.vue";
import LinkMpesaPayments from "./components/Admin/Finance/mpesa/LinkMpesaPayments.vue";
import FinanceOrder from "./components/v4/admin/finance/orders/FinanceOrder.vue";
import AdminNotifications from "./components/Notifications/AdminNotifications.vue";
const i18n = createI18n({
    locale: 'en',
    allowComposition: true,
})

app.use(i18n)

const user = useAuthStore();
user.updateUserDetails();

app.config.productionTip = false;

app.mount('#app')
