<script setup>
import {onMounted, ref, computed} from "vue";
import {Bootstrap5Pagination} from "laravel-vue-pagination";
import moment from "moment/moment";
import Swal from "sweetalert2";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps([
    "total",
    "totalPaid",
    "totalUnpaid",
    "totalOverdue",
    "states",
    "markets",
    "salesOfficers",
]);
const orders = ref({});
const errors = ref({});
const searchOrder = ref("");
const filterOrder = ref("");
const filterBy = ref("");
const filterByPeriod = ref("");
const selectedOrder = ref({});
const confirmationType = ref("");
const approving = ref(false);
const declining = ref(false);
const fetchingData = ref(false);

const setSearchOrder = (value) => {
    searchOrder.value = value
}

const setFilterByPeriod = (value) => {
    filterByPeriod.value = value
}

const setOrders = (value) => {
    orders.value = value
}
const setFetchingData = (value) => {
    fetchingData.value = value;
};

const setFilterOrder = (value) => {
    filterOrder.value = value;
};
const setFilterBy = (value) => {
    filterBy.value = value;
};

$(document).ready(function () {
    $(".data-attributes span").peity("donut");
});

const isFilterType = computed(() => filterBy.value === "type");
const isFilterByState = computed(() => filterBy.value === "state");
const isFilterByMarket = computed(() => filterBy.value === "market");
const isFilterByStatus = computed(() => filterBy.value === "status");
const isFilterSalesOfficer = computed(() => filterBy.value === "sales_officer");

const filtersQueryString = computed(() => {
    let data = {};
    if (filterBy.value && filterOrder.value) {
        switch (filterBy.value) {
            case "type":
                data = {
                    "filters[type]": filterOrder.value,
                };
                break;
            case "state":
                data = {
                    "filters[state]": filterOrder.value,
                };
                break;
            case "market":
                data = {
                    "filters[market]": filterOrder.value,
                };
                break;
            case "status":
                data = {
                    "filters[status]": filterOrder.value,
                };
                break;
            case "sales_officer":
                data = {
                    "filters[sales_officer]": filterOrder.value,
                };
                break;
        }

        return new URLSearchParams(data).toString();
    }

    return "";
});

function onChangeFilterOrders() {
    getOrders();
}

function clearFilters() {
    setSearchOrder("")
    setFilterByPeriod("")
    setFilterBy("");
    setFilterOrder("");
    getOrders();
}

const getOrders = async (page = 1) => {
    setFetchingData(true);

    if (page === 1) {
        setOrders({})
    }

    await axios
        .get(
            `/apis/v4/saas/orders?page=${page}&filter_period=${filterByPeriod.value}&keyword=${searchOrder.value}&${filtersQueryString.value}`,
        )
        .then((resp) => {
            setOrders(resp.data.data);
        })
        .catch((err) => {
            errors.value = err.response.data.errors;
        });
    setFetchingData(false);
};

function formatDate(value, format = "DD/MM/YYYY HH:mm:ss") {
    return moment(value).format(format);
}

function populateOrder(order) {
    if (order) {
        selectedOrder.value = order;
    }
}

function setConfirmation(type) {
    confirmationType.value = type;
}

function approveOrDeclineOrder(type, selectedOrder) {
    approving.value = true;
    declining.value = true;
    let url =
        type === "approve"
            ? "/four/admin/orders/approve"
            : "/four/admin/orders/approve?reject=1";

    axios
        .post(url, {
            order_id: selectedOrder?.id,
        })
        .then((resp) => {
            location.reload();
        })
        .catch((err) => {
            approving.value = false;
            declining.value = false;
            let defaultErrorMsg =
                "Error approving/declining the order. Contact our support team if problem persists.";
            let errorMsg =
                err?.response?.data?.trace?.error[0] ?? defaultErrorMsg;

            Swal.fire({
                position: "center",
                icon: "error",
                title: `<h5>${errorMsg}</h5>`,
            }).then(() => {
                location.reload();
            });
        });
}

onMounted(function () {
    getOrders();
});

const currentPage = computed(() => {
    return orders.value.current_page ?? 1;
});
const perPage = computed(() => {
    return orders.value.per_page ?? 20;
});
</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col">
                            <div class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["#18A00D", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalPaid }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Paid</p>
                                        <h3 class="mb-0">{{ totalPaid }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["orange", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalUnpaid }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Unpaid</p>
                                        <h3 class="mb-0">{{ totalUnpaid }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["red", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalOverdue }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Overdue</p>
                                        <h3 class="mb-0">{{ totalOverdue }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6">
                                <form @submit.prevent="getOrders()">
                                    <div class="input-group mb-1">
                                        <input
                                            id="searchOrder"
                                            type="text"
                                            class="form-control"
                                            placeholder="Search by order #, buyer name or product"
                                            name="search"
                                            v-model="searchOrder"
                                            aria-label="Search Orders"
                                            aria-describedby="button-addon2"
                                        >
                                        <button id="performSearchOrder" class="btn btn-outline-secondary" type="submit">
                                            Search
                                            <i class="fa fa-search"/>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-6">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <select v-model="filterBy" class="form-select">
                                                <option value="">-- All Filters --</option>
                                                <option value="type">Type</option>
                                                <option value="state">State</option>
                                                <option value="market">Market</option>
                                                <option value="status">Status</option>
                                                <option value="sales_officer">Sales Officer</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <select
                                                v-if="isFilterByState"
                                                v-model="filterOrder"
                                                class="form-select"
                                                @change="onChangeFilterOrders"
                                            >
                                                <option value="">-- All States --</option>
                                                <option v-for="state in states" :key="state.id" :value="state.id">
                                                    {{ state.name }}
                                                </option>
                                            </select>
                                            <select
                                                v-else-if="isFilterByMarket"
                                                v-model="filterOrder"
                                                class="form-select"
                                                @change="onChangeFilterOrders"
                                            >
                                                <option value="">-- All Markets --</option>
                                                <option v-for="market in markets" :key="market.id" :value="market.id">
                                                    {{ market.name }}
                                                </option>
                                            </select>
                                            <select
                                                v-else-if="isFilterSalesOfficer"
                                                v-model="filterOrder"
                                                class="form-select"
                                                @change="onChangeFilterOrders"
                                            >
                                                <option value="">-- All Sales Officers --</option>
                                                <option v-for="so in salesOfficers" :key="so.id" :value="so.id">
                                                    {{ so.name }}
                                                </option>
                                            </select>
                                            <select
                                                v-else-if="isFilterType"
                                                v-model="filterOrder"
                                                class="form-select"
                                                @change="onChangeFilterOrders"
                                            >
                                                <option value="">-- All Types --</option>
                                                <option value="bnpl">Bnpl</option>
                                                <option value="purchase">Purchase</option>
                                            </select>
                                            <select
                                                v-else-if="isFilterByStatus"
                                                v-model="filterOrder"
                                                class="form-select"
                                                @change="onChangeFilterOrders"
                                            >
                                                <option value="">-- All Status --</option>
                                                <option value="approved">Approved</option>
                                                <option value="rejected">Rejected</option>
                                                <option value="paid">Paid</option>
                                                <option value="active">Pending Payment</option>
                                                <option value="overdue">Overdue</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <select v-model="filterByPeriod" class="form-select" @change="getOrders()">
                                                <option value="">-- All Time --</option>
                                                <option value="today">Today</option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="week">Week</option>
                                                <option value="month">Month</option>
                                                <option value="quarter">Quarter</option>
                                                <option value="year">Year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-sm btn-link mb-2" @click="clearFilters">
                            Clear Filters
                        </button>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="orders && orders.data && orders.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Order #</th>
                                <th>Type</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Product</th>
                                <th>Total</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody v-if="orders && orders.data && orders.data.length > 0">
                            <tr v-for="(order, index) in orders.data" :key="order.id">
                                <td scope="row">{{ (currentPage - 1) * perPage + (index + 1) }}</td>
                                <td>{{ order.order_id }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span v-if="order.pay_with_working_capital" class="badge bg-primary">
                                            bnpl
                                        </span>
                                        <span v-else class="badge bg-light text-dark">
                                            purchase
                                        </span>
                                    </div>
                                </td>
                                <td>{{ order.buyer?.phone ?? "---" }}</td>
                                <td>{{ order.seller?.phone ?? "---" }}</td>
                                <td>{{ order.summary_line }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="badge bg-dark">{{ currency }}{{order.total.toLocaleString() }}</span>
                                    </div>
                                </td>
                                <td>{{ formatDate(order.created_at, "DD/MM/YYYY") }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span v-if="order.order_status?.class === 'info'" class="badge bg-info text-dark">
                                            {{ order.order_status?.name }}
                                        </span>
                                        <span v-else-if="order.order_status?.class === 'warning'" class="badge bg-warning text-dark">
                                            {{ order.order_status?.name }}
                                        </span>
                                        <span v-else-if="order.order_status?.class === 'success'" class="badge bg-success">
                                            {{ order.order_status?.name }}
                                        </span>
                                        <span v-else-if="order.order_status?.class === 'danger'" class="badge bg-danger">
                                            {{ order.order_status?.name }}
                                        </span>
                                        <span v-else-if="order.order_status?.class === 'default'" class="badge bg-secondary">
                                            {{ order.order_status?.name }}
                                        </span>
                                        <span v-else class="badge bg-dark">{{ order.order_status?.name }}</span>
                                    </div>
                                </td>
                                <td>
                                    <button
                                        class="btn btn-sm btn-outline-primary"
                                        data-bs-toggle="offcanvas"
                                        href="#viewOrder"
                                        role="button"
                                        aria-controls="viewOrder"
                                        @click="populateOrder(order)"
                                    >
                                        View Order
                                        <i class="fa fa-angle-double-right fa-thin"/>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td class="text-center" colspan="7">
                                    <div v-if="fetchingData" class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <span v-else> No record found. </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="orders" style="overflow: scroll" @pagination-change-page="getOrders"/>
                        </div>
                    </div>
                    <div id="viewOrder" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="viewOrderLabel">
                        <div class="offcanvas-header">
                            <h5 id="viewOrderLabel" class="offcanvas-title">Order Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"/>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td>Order Number:</td>
                                        <td><b>{{selectedOrder?.order_id }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Order Type:</td>
                                        <td>
                                            <b>{{ selectedOrder?.pay_with_working_capital ? "BNPL" : "PURCHASE" }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Product:</td>
                                        <td><b>{{ selectedOrder?.summary_line }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Name:</td>
                                        <td><b>{{ selectedOrder?.buyer?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Phone:</td>
                                        <td><b>{{ selectedOrder?.buyer?.phone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Market:</td>
                                        <td><b>{{ selectedOrder?.buyer_group?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Name:</td>
                                        <td><b>{{ selectedOrder?.seller?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Phone:</td>
                                        <td><b>{{ selectedOrder?.seller?.phone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Market:</td>
                                        <td><b>{{ selectedOrder?.seller_group?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Bank:</td>
                                        <td><b>{{ selectedOrder?.seller_bank?.name ?? "---" }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Account Number:</td>
                                        <td><b>{{ selectedOrder?.seller?.account_number ?? "---" }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Name:</td>
                                        <td><b>{{ selectedOrder?.agent_user?.name ?? "---" }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Phone:</td>
                                        <td><b>{{ selectedOrder?.agent_user?.phone ?? "---" }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sales Officer Name:</td>
                                        <td><b>{{ selectedOrder?.sales_officer?.name ?? "---" }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Total Order Value:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                                {{ currency }}{{ selectedOrder?.total?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Product Value:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                                {{ currency }}{{selectedOrder?.items_price?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Fees Applied:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.fees?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Charges:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.charges?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Agent Commission:</td>
                                        <td>
                                            <span v-if="selectedOrder?.agent_fee" class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.agent_fee?.toLocaleString() }}
                                            </span>
                                            <b v-else>---</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Market Leader Commission:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.market_leader_commission?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sales Officer Commission:</td>
                                        <td>
                                            <span v-if="selectedOrder?.sales_officer_id" class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.sales_officer_commission?.toLocaleString() }}
                                            </span>
                                            <b v-else>---</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Contribution:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                              {{ currency }}{{ selectedOrder?.insurance_contribution?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Penalty Total Repayment:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                                +{{ currency }}{{ selectedOrder?.bnpl_fees_incurred?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>
                                            <span v-if="selectedOrder.order_status?.class === 'info'" class="badge bg-info text-dark">
                                              {{ selectedOrder.order_status?.name }}
                                            </span>
                                            <span v-else-if="selectedOrder.order_status?.class ==='warning'" class="badge bg-warning text-dark">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                            <span v-else-if="selectedOrder.order_status?.class === 'success'" class="badge bg-success">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                            <span v-else-if="selectedOrder.order_status?.class === 'danger'" class="badge bg-danger">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                            <span v-else-if="selectedOrder.order_status?.class ==='default'" class="badge bg-secondary">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                            <span v-else class="badge bg-dark">
                                                {{selectedOrder.order_status?.name }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Order Datetime:</td>
                                        <td><b>{{formatDate(selectedOrder?.created_at)}}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div
                                v-if="selectedOrder?.order_status?.slug === 'new'"
                                class="align-center"
                                style="text-align: center"
                            >
                                <button
                                    class="btn btn-outline-success"
                                    style="width: 100%"
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmModal"
                                    @click="setConfirmation('approve')"
                                >
                                    Approve
                                </button>
                                <button
                                    class="btn btn-outline-warning btn-warn-outline mt-2"
                                    style="width: 100%"
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmModal"
                                    @click="setConfirmation('decline')"
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        id="confirmModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirmModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button style="float: right" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12" style="padding: 10px">
                        <h4 v-if="confirmationType === 'approve'" class="text-success" style="font-weight: 600">
                            Confirm Approve Order
                        </h4>
                        <h4 v-else class="" style="font-weight: 600; color: darkorange">Confirm Decline Order</h4>

                        <div class="mt-3" style="font-weight: 500">
                            <p v-if="confirmationType === 'approve'">
                                By approving this order, the sum of
                                {{ currency }}{{ selectedOrder?.items_price?.toLocaleString() }}
                                would be paid to the seller's account
                            </p>
                            <p v-else>
                                By declining this order, the order will be cancelled and the buyer and seller notified
                            </p>
                        </div>

                        <div class="mt-4">
                            <button
                                v-if="confirmationType === 'approve'"
                                class="btn btn-success"
                                style="width: 100%"
                                @click.prevent="approveOrDeclineOrder('approve',selectedOrder)"
                            >
                                Approve
                                <i v-if="approving" class="fa fa-spin fa-spinner"/>
                            </button>

                            <button
                                v-else
                                class="btn btn-warning btn-warn"
                                style="width: 100%"
                                @click.prevent="approveOrDeclineOrder('decline',selectedOrder)"
                            >
                                Decline
                                <i v-if="declining" class="fa fa-spin fa-spinner"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}

.page-link:hover {
    color: #00ab4d;
}

.active > .page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}

.btn-warn-outline {
    color: darkorange !important;
    border-color: darkorange !important;
}

.btn-warn-outline:hover {
    color: #fff !important;
    background-color: darkorange !important;
}

.btn-warn {
    color: #fff !important;
    background-color: darkorange !important;
}

.btn-warn:hover {
    color: #fff !important;
    background-color: orange !important;
}
</style>
